<template>
  <div>
    <div v-for="(item, index) in imageData" :key="index" class="imgItem" @click="jump(index)">
      <img :src="item" alt="" />
    </div>
  </div>
</template>
<script>
import mobileBanner from "./homeComponents/mobileBanner.vue";
export default {
  components: {
    mobileBanner,
  },
  data() {
    return {
      imageData: [
        "https://cdn-public.nio.com/nioappadmin/hhRjzO_TX-Csv51kXauuV/cab2fbe5-d02d-4eaf-b3e6-2c8f040ff229.jpg",
        "https://cdn-public.nio.com/nioappadmin/QX_f7D-tRWNTCaFiJC1qQ/5e446f86-7212-40b5-9138-0f4535ea68f4.jpg",
        "https://cdn-public.nio.com/nioappadmin/LEENLfOsChMUyG1WmS4Xj/e64ce51e-812a-4868-8e9e-c633293358b9.jpg",
        "https://cdn-public.nio.com/nioappadmin/gpVa2RTebqN-KNczvGqwW/84f00344-528f-4d5f-8474-ceb8ff97185d.jpg",
        "https://cdn-public.nio.com/nioappadmin/Vz4IYYPu5Ro8PDpmQfXP3/06dca4f9-d28d-46d0-bfcd-61d40a4fdab7.jpg",
      ],
    };
  },
  methods: {
    jump(index) {
      if (index !== this.imageData.length - 1) return;
      location.href =
        "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7392930971669285147&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=7394352401153657142";
    },
  },
};
</script>
<style scoped lang="less">
.imgItem {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
  }
}

.button {
  position: absolute;
  bottom: 250px;
  left: 150px;
  width: 450px;
  height: 150px;
  cursor: pointer;
}
</style>
